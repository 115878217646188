import { createSlice } from "@reduxjs/toolkit";


const alertSlice = createSlice({
    name: "alert",
    initialState: {
        alerts: [
            //   { 
            //      text:'',
            //      id : 0,
            //      timeout:0
            //   }
        ]
    },
    reducers: {
        AlertAdd(state, action){
            state.alerts.push(action.payload)
        },
        
        AlertRemove(state,action){
            //console.log('action>>>',action);
            // const itemIndex =  state.alerts.findIndex(res=>res.id === action.payload)
            // state.alerts = [...state.alerts.slice(0,itemIndex),...state.alerts.slice(itemIndex+1)]
            state.alerts = state.alerts.filter(alert=>alert.id !== action.payload.id)
        },
    },
})


export const {AlertAdd, AlertRemove} = alertSlice.actions
export default alertSlice.reducer